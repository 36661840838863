<template>
  <section class="organism-other-transaction-expenses">
    <div
      class="expense-container"
      v-for="(expense, index) in expenses"
      :key="`other-transaction-expense-${index}`"
    >
      <div class="flex justify-space-between align-center mb-16">
        <bg-text>Pengeluaran {{ index + 1 }}</bg-text>
        <bg-button
          variant="secondary"
          class="icon-button"
          :data-testid="`delete-expense-${index}`"
          :disabled="disabledDelete"
          @click="showDeleteModal(true, index)"
        >
          <bg-icon name="delete" class="icon-spacing" size="sm" />
        </bg-button>
      </div>

      <bg-grid>
        <bg-grid-item class="mb-16" :col="4">
          <bg-field class="mb-0" label="Kategori Pengeluaran">
            <bg-select
              v-model="expense.category_id"
              placeholder="Pilih kategori pengeluaran"
              :data-testid="`expense-category-${index}`"
              :options="options.expense_category"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item class="mb-16" :col="4">
          <bg-field class="mb-0" label="Nama Pengeluaran">
            <bg-input
              v-model="expense.name"
              placeholder="Masukkan nama pengeluaran"
              maxlength="50"
              :data-testid="`expense-name-${index}`"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item class="mb-16" :col="4">
          <bg-field class="mb-0" label="Kuantitas">
            <bg-input
              v-model="expense.quantity"
              placeholder="Kuantitas"
              :data-testid="`expense-qty-${index}`"
              @input="onlyNumber($event, index)"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item class="mb-16" :col="4">
          <input-currency-masking
            v-model="expense.amount"
            field-label="Nominal Pengeluaran"
            prefix="Rp"
            :data-testid="`expense-amount-${index}`"
          />
        </bg-grid-item>

        <bg-grid-item class="mb-16" :col="4">
          <bg-field class="mb-0" label="Status Persediaan">
            <bg-select
              v-model="expense.is_stock_available"
              placeholder="Pilih status persediaan"
              :data-testid="`expense-stock-status-${index}`"
              :options="localOptions.stockStatus"
            />
          </bg-field>
        </bg-grid-item>

        <bg-grid-item class="mb-16" :col="4">
          <bg-field class="mb-0" label="Jenis Produk">
            <bg-select
              v-model="expense.product_id"
              placeholder="Pilih jenis produk"
              :data-testid="`expense-product-${index}`"
              :options="options.product_type"
            />
          </bg-field>
        </bg-grid-item>
      </bg-grid>

      <bg-divider class="mb-16" />
    </div>

    <div>
      <bg-button
        variant="secondary"
        :disabled="disabled.add || exceedTotalExpenses"
        data-testid="add-expense"
        @click="addNewExpense"
      >
        Tambah Pengeluaran
      </bg-button>
    </div>

    <bg-modal
      v-model="showModal.delete"
      title="Yakin hapus biaya data ini?"
      description="Data yang dihapus tidak dapat dikembalikan"
      button-main-text="Batal"
      button-second-text="Hapus"
      @click-main-action="showDeleteModal(false)"
      @click-second-action="deleteExpense"
    />
  </section>
</template>

<script>
import {
  BgButton,
  BgDivider,
  BgText,
  BgIcon,
  BgField,
  BgSelect,
  BgInput,
  BgGrid,
  BgGridItem,
  BgModal,
} from 'bangul-vue';

import { onlyNumber, cleanNumber } from 'Utils/formatter';

export default {
  name: 'OtherTransactionExpenses',

  components: {
    BgButton,
    BgDivider,
    BgText,
    BgIcon,
    BgField,
    BgSelect,
    BgInput,
    BgGrid,
    BgGridItem,
    BgModal,
    InputCurrencyMasking: () => import('@molecules/InputCurrencyMasking'),
  },

  props: {
    options: {
      type: Object,
      default: () => {},
    },
    presetData: {
      type: Array,
      default: () => [
        {
          amount: '',
          category_id: '',
          name: '',
          product_id: null,
          quantity: null,
          is_stock_available: '',
        }
      ],
    }
  },

  data() {
    return {
      expenses: [
        {
          amount: '',
          category_id: '',
          name: '',
          product_id: null,
          quantity: null,
          is_stock_available: '',
        },
      ],

      showModal: {
        delete: false,
      },

      disabled: {
        add: true,
      },

      isLoading: {
        property: true,
        options: true,
      },

      temp: {
        expenseIndex: null,
      },
    };
  },

  computed: {
    disabledDelete() {
      return this.expenses.length < 2;
    },
    formattedExpenses() {
      return this.expenses.map(expense => ({
        ...expense,
        quantity: expense.quantity ? cleanNumber(expense.quantity) : null,
        amount: expense.amount ? cleanNumber(expense.amount) : 0,
      }));
    },
    localOptions() {
      return {
        stockStatus: [
          {
            val: '0',
            label: 'Non Stock',
          },
          {
            val: '1',
            label: 'Stock',
          },
        ],
      }
    },
    exceedTotalExpenses() {
      return this.expenses.length >= 30;
    },
  },

  watch: {
    formattedExpenses: {
      handler(newValue) {
        const allFilled = newValue.reduce(
          (prevBool, nextObj) =>
            prevBool &&
            Object.values(nextObj).reduce(
              (prevChildBool, nextVal) => prevChildBool && Boolean(nextVal),
              true
            ),
          true
        );
        this.disabled.add = !allFilled;
      },
      deep: true,
    },
    presetData: {
      immediate: true,
      handler(newValue) {
        this.expenses = newValue;
      }
    }
  },

  updated() {
    this.$emit('is-all-expenses-filled', !this.disabled.add);
    if (!this.disabled.add) {
      this.$emit('get-expenses', this.formattedExpenses);
    }
  },

  methods: {
    onlyNumber(value, index) {
      this.expenses[index].quantity = onlyNumber(value);
    },
    cleanNumber(value) {
      return cleanNumber(value);
    },
    addNewExpense() {
      this.expenses.push({
        amount: '',
        category_id: '',
        name: '',
        product_id: null,
        quantity: null,
        is_stock_available: '',
      });
    },
    showDeleteModal(show, index = null) {
      this.temp.expenseIndex = index;
      this.showModal.delete = show;
    },
    deleteExpense() {
      this.expenses.splice(this.temp.expenseIndex, 1);
      this.$emit('get-expenses', this.formattedExpenses);
      this.showDeleteModal(false);
      this.temp.expenseIndex = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.organism-other-transaction-expenses {
  background-color: #fafafa;
  padding: 16px;
}

.icon-button {
  padding: 8px;
  line-height: 1;
  .bg-c-icon {
    vertical-align: top;
  }
}
</style>
